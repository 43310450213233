import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { configData } from '../config.data';
import { UserService } from '../components/userlogin/user.service';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { types } from 'util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppalertsComponent } from '../components/appalerts/appalerts.component';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public expressLink = configData.SERVER;
  public progress = new Subject<boolean>();
  public userLoginSuccess = new Subject<boolean>();
  public dialogSubmitEvent = new Subject<any>();
  public variableDropdownEvent = new Subject<any>();
  public currentTheme;

  constructor(private http: HttpClient, private router: Router, private user: UserService, public snackBar: MatSnackBar, private zone: NgZone) {
    this.currentTheme = localStorage.getItem('defaultThemeColour');
  }

  // Check and put lock on record
  checkLock(object, id) {
    return this.http.get(this.expressLink + 'checkLock/' + object + '/' + id + '/?autor=' + this.user.loggedinUser.username, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Clear lock on record
  clearLock(id) {
    return this.http.get(this.expressLink + 'clearLock/' + id, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Get all types
  getTypes(): Observable<any> {
    return this.http.get(this.expressLink + 'types', {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  getProgressValue(): Observable<any> {
    return this.progress.asObservable();
  }

  getUserLoginSuccess(): Observable<any> {
    return this.userLoginSuccess.asObservable();
  }

  public open(message, action) {
    this.zone.run(() => {
      switch (action) {
        case 'success':
          this.snackBar.openFromComponent(AppalertsComponent, {
            duration: 4000, data: { message, icon: 'check' }, panelClass: ['alert-success'],
            verticalPosition: 'top', horizontalPosition: 'end'
          });
          break;
        case 'failure':
          this.snackBar.openFromComponent(AppalertsComponent, {
            data: { message, icon: 'close' }, panelClass: ['alert-danger'], verticalPosition: 'top', horizontalPosition: 'end'
          });
          break;
        case 'warning':
          this.snackBar.openFromComponent(AppalertsComponent, {
            data: { message, icon: 'close' }, panelClass: ['alert-warning'], verticalPosition: 'top', horizontalPosition: 'end'
          });
          break;
      }
    });
  }

  logoutUser() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  listenerLogin() {
    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage) {
        const token = localStorage.getItem('token');
        if (!this.router.url.includes('resetpwdlink')) {
          if (token === null) {
            this.logoutUser();
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      }
    });
  }

  loggedIn() {
    return !!localStorage.getItem('token'); // !undefined is true. So !!undefined is false
  }

  userDetail(){
    return localStorage.getItem('userDetail') ? JSON.parse(window.atob(localStorage.getItem('userDetail'))) : '';
  }

  getDialogSubmitEvent() {
    return this.dialogSubmitEvent.asObservable();
  }

  getVariableDropdownEvent() {
    return this.variableDropdownEvent.asObservable();
  }
}
